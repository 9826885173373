<template>
  <div
    class="application-main"
    :class="{
      biote: isBioteEnv,
    }"
  >
    <NewMainHeader />
    <MainSidebar />
    <transition name="slide-fade" mode="out-in">
      <div class="main-content">
        <template>
          <router-view v-if="getAllAvailableLocations" class="content-wrapper" />
        </template>
        <MainFooter class="content-footer" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { isEmpty } from "lodash";
import VueCookies from "vue-cookies";
import NewMainHeader from "@/components/shared-components/master-layout/components/new-main-header/NewMainHeader.vue";
import MainSidebar from "@/components/common/MainSidebar.vue";
import MainFooter from "@/components/common/MainFooter.vue";

export default {
  name: "MainContent",
  components: {
    NewMainHeader,
    MainSidebar,
    MainFooter,
  },
  data() {
    return {
      active: true,
      isPatient: "",
      userType: "",
      background: "",
      isPageLoaded: false,
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser", "getStep", "getShowWelcome", "getSuperAdminPermission"]),
    ...mapGetters("usersModule", ["isLoggedIn"]),
    ...mapGetters("locationsModule", ["getAllAvailableLocations"]),
    dismissed() {
      if (localStorage.getItem("covidBanner")) {
        const status = localStorage.getItem("covidBanner");
        return status === "false";
      }
      return false;
    },
    patientPortal() {
      return `${process.env.VUE_APP_PATIENT_MEDCENTER}`;
    },
    isCallbackRoute() {
      const currentRoute = this.$route;
      return currentRoute.name === "callback" && !isEmpty(currentRoute.query.code);
    },
    isBioteEnv() {
      return process.env.NODE_ENV === "biote";
    },
  },
  beforeRouteUpdate(to, from, next) {
    const oContainer = document.querySelector(".main-content");
    oContainer.scrollTop = 0;
    next();
  },
  async mounted() {
    if (this.isLoggedIn) {
      // if (this.getSuperAdminPermission === null) {
      //   await this.fetchSubSupperAdminDetails(this.getCurrentUser.id);
      // }
    }

    this.userType = localStorage.userType === "practiceUser" ? localStorage.userType : "orgUser";
    this.background = localStorage.color ? localStorage.color : "#374458";

    const PERMITTED_DOMAIN_1 = `${process.env.VUE_APP_LAB_TEST_PORTAL}`;
    const PERMITTED_DOMAIN_2 = `${process.env.VUE_APP_PATIENT_MEDCENTER}`;
    const PERMITTED_DOMAIN_3 = `${process.env.VUE_APP_BHRT_PORTAL}`;
    window.addEventListener(
      "message",
      (event) => {
        if (
          event.origin === PERMITTED_DOMAIN_2 ||
          event.origin === PERMITTED_DOMAIN_1 ||
          event.origin === PERMITTED_DOMAIN_3
        ) {
          const accessToken = event.data.access_token ? event.data.access_token : "";
          const idToken = event.data.id_token ? event.data.id_token : "";
          const expTime = event.data.exp ? event.data.exp : "";
          const landingPage = event.data.pageName ? event.data.pageName : "labtest";
          const productArrayString = event.data.productArrayString ? event.data.productArrayString : "";
          const cookie = event.data.cookie ? event.data.cookie : "";
          if (event.data) {
            localStorage.setItem("color", event.data.color);
            localStorage.setItem("userType", event.data.userType);
            localStorage.setItem("practiceId", event.data.practiceId);
            localStorage.setItem("contentColor", event.data.contentColor);
            localStorage.setItem("access_token", accessToken);
            localStorage.setItem("id_token", idToken);
            localStorage.setItem("landingPage", landingPage);
            localStorage.setItem("exp", expTime);
            localStorage.setItem("productArrayString", productArrayString);
            VueCookies.set("productArrayString", productArrayString);
            VueCookies.set("test", cookie);
          }
        }
      },
      false
    );
    this.isPageLoaded = true;
  },
  methods: {
    ...mapActions(["commitCurrentUserType", "fetchSubSupperAdminDetails", "fetchUserLocations"]),
    dismiss() {
      this.active = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .main-loader .anticon {
    font-size: 60px !important;
    color: #ff4d0a;
  }
}
.main-loader {
  height: 75%;
  position: absolute;
  width: 100%;
}
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.application-main {
  background-color: #f5f7fa;
  display: flex;
  flex-direction: column;
  height: 100%;

  > *:not(footer):not(.header):not(aside) {
    box-sizing: border-box;
    /* max-width: 1920px; need to refactor site to deal with full width content */
    width: 100%;
    margin: 0 auto;
  }

  footer {
    margin-top: auto;
  }
}

.main-content {
  overflow: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.content-wrapper {
  height: auto;
  width: 100%;
}
.content-footer {
  width: 100%;
  margin-top: auto;
}
</style>
