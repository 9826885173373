<template>
  <footer
    class="footer-wrapper"
    :class="{
      biote: isBioteEnv
    }"
  >
    <template v-if="!isBioteEnv">
      <el-row type="flex" justify="center" class="footer-content-wrapper">
        <el-col :xs="24" :sm="20" :md="16">
          <el-row type="flex" justify="center" class="footer-content-container">
            <el-col :xs="12" :sm="8" :md="8" :lg="6" :xl="4">
              <p class="heading">Address</p>
              <p class="address">
                <span>15210 N. Scottsdale Rd.</span>
                <span>Suite 210</span>
                <span>Scottsdale, AZ 85254</span>
              </p>
            </el-col>
            <el-col :xs="12" :sm="8" :md="8" :lg="6" :xl="4">
              <p class="heading">
                {{ $t("footerMain.connectWithUs") }}
              </p>
              <div class="contact-info">
                <p>
                  <span class="icon-container">
                    <font-awesome-icon class="icon" icon="envelope" />
                  </span>
                  <span>
                    <a href="mailto:support@simpatra.com">support@simpatra.com</a>
                  </span>
                </p>
                <p>
                  <span class="icon-container">
                    <font-awesome-icon icon="mobile-alt" />
                  </span>
                  <span>
                    <a href="tel:4806824850">480-682-4850</a>
                  </span>
                </p>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <el-row class="copyright">
        <el-col :xs="24" :sm="24">
          <ul>
            <li>&copy; {{ new Date().getFullYear() }} Simpatra, Inc.</li>
            <li>{{ $t("footerMain.rightsReserved") }}</li>
            <li class="cursor-pointer hoverable" @click="navigateTo('terms-of-service')">Terms of Service</li>
            <li class="cursor-pointer hoverable" @click="navigateTo('privacy-policy')">Privacy Policy</li>
          </ul>
        </el-col>
      </el-row>
    </template>
  </footer>
</template>

<script>
// import VueCookies from 'vue-cookies';
// import VueI18n from '@/plugins/i18n';

export default {
  name: "MainFooter",
  data() {
    return {
      userType: "",
      practiceId: ""
    };
  },
  computed: {
    isBioteEnv() {
      return process.env.NODE_ENV === "biote";
    }
  },
  mounted() {
    this.userType = localStorage.userType === "practiceUser" ? localStorage.userType : "orgUser";
    this.practiceId = localStorage.practiceId ? localStorage.practiceId : "";
  },
  methods: {
    navigateTo(page) {
       this.$router.push(
        {
          path: `/${page}`,
        }
     )
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_colors.scss";
.footer-wrapper {
  margin-top: 1rem;
  padding: 15px;
  &:not(.biote) {
    background-color: $dark-blue;
    height: 8.5rem;
    .hoverable:hover {
      color: $simpatra-orange;
    }
  }
  &.biote {
    min-height: 5rem;
    background-color: $biote--primary-color;
    .hoverable:hover {
      color: $biote--primary-color;
    }
  }
}
footer {
  background-size: cover;
  color: $white;
  font-size: 14px;

  a {
    color: $white;
    font-weight: 200;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }

  p {
    line-height: 1.5;
    margin-bottom: 0;
    margin-top: 0.25em;

    span {
      font-size: 0.9em;
      font-weight: 200;
      display: block;
    }
  }

  ul {
    list-style-type: none;
    margin-bottom: 0;
    padding: 0;

    li {
      font-size: 0.9em;
      font-weight: 200;
      letter-spacing: 0.25px;
      line-height: 1.5;
    }
  }

  .footer-content-wrapper {
    padding-top: 10px;
    .footer-content-container {
      padding-left: 6.5rem;
      flex-wrap: wrap;
    }
  }

  .heading {
    font-size: 1.1em;
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
    margin: 0;
  }

  .address,
  .contact-info {
    margin-bottom: 0.5em;
  }

  .contact-info {
    p {
      display: flex;
      align-items: center;

      .icon-container {
        flex-basis: 1.75em;
      }
    }

    /*> div {*/
    /*margin-right: 1em;*/
    /*}*/
  }

  .copyright {
    ul {
      font-size: 10px;
      font-weight: 200;
      letter-spacing: 0.5px;
      text-align: center;
      text-transform: uppercase;

      li {
        display: inline-block;
        line-height: 0.8;

        &:not(:first-of-type) {
          border-left: 1px solid $white;
          margin-left: 1.5em;
          padding-left: 1.5em;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .footer-content-container {
    text-align: center;
    padding: 0 !important;

    .el-col {
      margin-top: 1em;
    }
  }
  .contact-info {
    p {
      justify-content: center;
    }
  }
}
.icon {
  margin-right: 10px;
}
</style>

