<template>
  <el-aside class="sidebar-main">
    <router-link
      class="nav-link pharmacy"
      :to="{name: 'CategoryPage', params: {category: 'pharmacy'}}"
    >
      Pharmacy <a-icon
        type="close-square-o"
        @click="closeToggle"
      />
    </router-link>
    <router-link
      class="nav-link"
      :to="{name: 'CategoryPage', params: {category: 'nutraceuticals'}}"
    >
      Nutraceuticals
    </router-link>
    <router-link
      class="nav-link"
      :to="{name: 'CategoryPage', params: {category: 'pharmaceuticals'}}"
    >
      Pharmaceuticals
    </router-link>
    <router-link
      class="nav-link"
      :to="{name: 'CategoryPage', params: {category: 'medical-supplies'}}"
    >
      Medical Supplies
    </router-link>
    <router-link
      class="nav-link"
      :to="{name: 'CategoryPage', params: {category: 'medical-equipment'}}"
    >
      Medical Equipment
    </router-link>
    <router-link
      class="nav-link"
      :to="{name: 'OrgLocations'}"
    >
      Admin
    </router-link>
  </el-aside>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MainSidebar',
  beforeRouteUpdate(to, from, next) {
    this.toggleOffCanvasContent();
    next();
  },
  methods: {
    toggleOffCanvasContent() {
      // if the menu is currently closed, open it
      if (this.$store.getters.offCanvasMenuStatus === false) {
        this.$store.commit('toggleOffCanvasMenu', {
          toggle: true,
        });
      } else {
        this.$store.commit('toggleOffCanvasMenu', {
          toggle: false,
        });
      }
    },
    closeToggle() {
      this.$store.commit('toggleOffCanvasMenu', {
        toggle: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
  .nav-link {
    text-decoration: none;
    color: #c7c7c7;
    padding: 25px 15px;
    text-transform: uppercase;
    font-size: 14px;
    margin: -1px 0;
    border-top: 1px solid #374458;
    border-bottom: 1px solid #374458;

    &.pharmacy {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .anticon.anticon-close-square-o {
    color: #ae7765;
    font-size: 22px;
    &:hover {
      color: #f05a28;
    }
  }

  @mixin sidebar-box-shadow-inset {
    -webkit-box-shadow: inset -8px 0 15px -8px #1b242f;
    -moz-box-shadow: inset -8px 0 15px -8px #1b242f;
    box-shadow: inset -8px 0 15px -8px #1b242f;
  }

  $sidebar-standard-padding: 20px;
  $sidebar-link-padding: 60px;

  .sidebar-main {
    z-index: 2;
    @include sidebar-box-shadow-inset;
    background-color: $medium-blue;
    color: $white;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: -300px;
    height: 100%;
  }
  @media(max-width: 767px) {
    .sidebar-main {
      width: 250px;
    }
  }
</style>
